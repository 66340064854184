import React, { useEffect, useState } from "react"
import HomeHero from "../components/HomeHero"
import Layout from "../components/Layout"
import NewsletterBanner from "../components/NewsletterBanner"
// import CurrentEvents from "../components/CurrentEvents"
import useTrends from "../hooks/useTrends"
import TrendsViewer from "../components/TrendsViewer"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { Box, MaxWidth } from "@sqymagma/elements"
import { graphql, useStaticQuery } from "gatsby"

const IndexPage = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            image
            imageAlt
          }
        }
      }
    `
  )

  const { title, description, image, imageAlt } = site.siteMetadata

  const trendsData = useTrends()
  const breakpoints = useBreakpoint()

  const [numOfCards, setNumOfCards] = useState(4)
  const [numOfCols, setNumOfCols] = useState()

  useEffect(() => {
    setNumOfCards(breakpoints.s && !breakpoints.l ? 4 : 3)
    setNumOfCols(breakpoints.s && !breakpoints.l ? 2 : 3)
  }, [breakpoints.s, breakpoints.l])

  return (
    <Layout
      seoTitle={title}
      seoDescription={description}
      seoImage={image}
      seoImageAlt={imageAlt}
      footerSimple={true}
    >
      <Box overflow="hidden">
        <HomeHero />
        <Box bg="ui03">
          <MaxWidth>
            <TrendsViewer cols={numOfCols} content={trendsData.slice(0, numOfCards)} />
          </MaxWidth>
        </Box>
        <NewsletterBanner />
        <MaxWidth>
          <TrendsViewer cols={numOfCols} content={trendsData.slice(numOfCards)} />
        </MaxWidth>
        {/* Not good enough content for this module yet */}
        {/* <CurrentEvents /> */}
      </Box>
    </Layout>
  )
}

export default IndexPage
