import React from "react"
import { Box, Flex, Text } from "@sqymagma/elements"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { SplitBgStyle } from "../styles"
import HeroContainer from "./HeroContainer"
import StyledMarkdown from "./StyledMarkdown"
import ReadMoreLink from "./ReadMoreLink"
import { homePage } from "../../data"

const HomeHero = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(name: { regex: "/home-hero/" }) {
        sharp: childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const breakpoints = useBreakpoint()
  const { missionTitle, missionDescription, missionUrl } = homePage

  return (
    <Box as="section">
      <HeroContainer
        mt={{ default: "l", xs: "3xl", m: 0 }}
        pt="4xl"
        pb="l"
        height="100%"
        minHeight={{ default: "auto", s: "560px", m: "640px", xl: "680px" }}
      >
        <Flex
          as="article"
          flexDirection={{ default: "column", m: "row" }}
          mb={{ default: 0, xs: "s", m: 0, l: "m" }}
        >
          <Box width={{ default: 1, m: 5 / 12 }}>
            <Text as="h1" mt="0" textStyle="title02" color="text01" maxWidth={{ xs: "80%" }}>
              {missionTitle}
            </Text>
          </Box>
          <Box width={{ default: 1, m: 7 / 12 }} pr={{ m: "0", l: "m" }}>
            <Text
              as="div"
              pt="10px"
              textStyle="lead"
              color="text01"
              mb={{ default: "l", xs: "xl", m: "l" }}
              css={`
                p {
                  ${p => p.theme.textStyle.lead}
                }
              `}
            >
              <StyledMarkdown source={missionDescription} />
            </Text>
            <ReadMoreLink to={missionUrl} label="Leer más" mb="l" />
          </Box>
        </Flex>
      </HeroContainer>
      <Box position="relative">
        <BackgroundImage Tag="section" fluid={image.sharp.fluid}>
          {breakpoints.m ? (
            <SplitBgStyle height={{ default: "55vh", m: "640px" }} />
          ) : (
            <Box height={{ default: "55vh", m: "640px" }} />
          )}
        </BackgroundImage>
      </Box>
    </Box>
  )
}
export default HomeHero
